import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

/**
 * @return {void}
 */
export function clickCallButton() {
  return trackCustomEvent({
    category: 'call button',
    action: 'click',
  })
}

/**
 * 
 * @param {string} status - success | failed
 * @return {void}
 */
export function submitRequestForm(status) {
  return trackCustomEvent({
    category: 'submit request form',
    action: status
  })
}